import { graphql, StaticQuery } from "gatsby";
import moment from "moment";
import React from "react";
import { render } from "react-dom";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import Sponsor from "./Sponsors/Sponsor";

const SponsorsFlexElem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 69px;

  > div {
    max-width: 200px;
    margin: 0 auto 50px auto;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;

    > a:nth-of-type(odd) {
      border-right: 1px solid ${colors.text};
    }
    > a:last-of-type {
      border-right: none;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    max-width: 1000px;
    justify-content: center;
    margin-bottom: 110px;

    > a:nth-of-type(odd) {
      border: none;
    }

    > a:nth-of-type(1),
    > a:nth-of-type(3),
    > a:nth-of-type(4),
    > a:nth-of-type(6),
    > a:nth-of-type(8),
    > a:nth-of-type(10),
    > a:nth-of-type(11),
    > a:nth-of-type(13),
    > a:nth-of-type(15),
    > a:nth-of-type(17),
    > a:nth-of-type(18),
    > a:nth-of-type(20),
    > a:nth-of-type(22),
    > a:nth-of-type(24),
    > a:nth-of-type(25),
    > a:nth-of-type(27),
    > a:nth-of-type(29),
    > a:nth-of-type(31),
    > a:nth-of-type(32),
    > a:nth-of-type(34),
    > a:nth-of-type(36),
    > a:nth-of-type(38),
    > a:nth-of-type(39),
    > a:nth-of-type(41),
    > a:nth-of-type(43),
    > a:nth-of-type(45),
    > a:nth-of-type(46),
    > a:nth-of-type(48) {
      border-right: 1px solid ${colors.text};
    }

    > a {
      width: 33%;
      margin: 15px 0;
      &:last-of-type {
        border: none;
      }
    }

    > a:nth-of-type(1),
    > a:nth-of-type(2),
    > a:nth-of-type(6),
    > a:nth-of-type(7),//
    > a:nth-of-type(8),
    > a:nth-of-type(9),
    > a:nth-of-type(13),
    > a:nth-of-type(14),
    > a:nth-of-type(15),
    > a:nth-of-type(16),
    > a:nth-of-type(20),
    > a:nth-of-type(21),
    > a:nth-of-type(22),
    > a:nth-of-type(23),
    > a:nth-of-type(27),
    > a:nth-of-type(28),
    > a:nth-of-type(29),
    > a:nth-of-type(30),
    > a:nth-of-type(34),
    > a:nth-of-type(35),
    > a:nth-of-type(36),
    > a:nth-of-type(37),
    > a:nth-of-type(41),
    > a:nth-of-type(42),
    > a:nth-of-type(43),
    > a:nth-of-type(44),
    > a:nth-of-type(48),
    > a:nth-of-type(49) {
      width: 34%;
    }
  }
`;

const Sponsors = ({ labels }) => (
  <StaticQuery
    query={graphql`
      query sponsors {
        allSponsor {
          edges {
            node {
              sponsorWithCategory {
                sponsorCategory
                attributes {
                  keywords
                  publishedTo
                  content
                  image {
                    fullsize
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data: any) => {
      const sponsors = data.allSponsor.edges.map((sponsor) => {
        const { node } = sponsor;
        const { sponsorCategory } = node.sponsorWithCategory;
        const sponsorSlug = sponsorCategory.split("-sponsors")[0];

        let afterBaseUrl = "";

        if (typeof window !== "undefined") {
          afterBaseUrl = window.location.href.substring(
            window.location.origin.length
          );
        }
        // Filter all outdated sponsors
        if (
          node.sponsorWithCategory.attributes.publishedTo !== "" &&
          moment(node.sponsorWithCategory.attributes.publishedTo).isBefore(
            new Date()
          )
        ) {
          return null;
        }
        if (
          typeof window !== "undefined" &&
          window.location.href.includes(sponsorSlug)
        ) {
          return (
            <Sponsor
              imgurl={node.sponsorWithCategory.attributes.image.fullsize}
              imglink={node.sponsorWithCategory.attributes.keywords}
              sponsorTitle={node.sponsorWithCategory.attributes.content}
            />
          );
        }
        if (labels) {
          const eventFoundInCategory = labels.find(
            (element) =>
              element.name.toLowerCase().replace("/", "-") === sponsorSlug
          );
          if (eventFoundInCategory) {
            return (
              <Sponsor
                imgurl={node.sponsorWithCategory.attributes.image.fullsize}
                imglink={node.sponsorWithCategory.attributes.keywords}
                sponsorTitle={node.sponsorWithCategory.attributes.content}
              />
            );
          }
        }

        if (
          typeof window !== "undefined" &&
          afterBaseUrl === "/" &&
          sponsorSlug === "homepage"
        ) {
          return (
            <Sponsor
              imgurl={node.sponsorWithCategory.attributes.image.fullsize}
              imglink={node.sponsorWithCategory.attributes.keywords}
              sponsorTitle={node.sponsorWithCategory.attributes.content}
            />
          );
        }

        if (
          typeof window !== "undefined" &&
          (afterBaseUrl === "/about/our-sponsors/" ||
            afterBaseUrl === "/about/our-sponsors") &&
          sponsorSlug === "homepage"
        ) {
          return (
            <Sponsor
              imgurl={node.sponsorWithCategory.attributes.image.fullsize}
              imglink={node.sponsorWithCategory.attributes.keywords}
              sponsorTitle={node.sponsorWithCategory.attributes.content}
            />
          );
        }
        return null;
      });
      return <SponsorsFlexElem>{sponsors}</SponsorsFlexElem>;
    }}
  />
);

export default Sponsors;
