import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors, spacers } from "../../styles/variables";
import MaxSidesWrapper from "../Wrappers/MaxSidesWrapper";
import FooterNav from "./FooterNav";

const UpperFooterElem = styled.div`
  background: ${colors.blue};
  color: white;
  overflow: auto;
  > div {
    position: relative;
  }
`;

const Logo = styled(Link)`
  display: block;
  width: 129px;
  height: 46px;
  margin: ${spacers.sides.mobile}px auto 20px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin: 60px auto 20px;
  }
`;

const Address = styled.address`
  font: 16px / 24px "Slate Light";
  font-style: normal;
  text-align: center;
  margin-bottom: 18px;
  color: white;

  span {
    display: none;
  }

  strong {
    display: block;
    color: white;
    font-family: 16px / 24px "Slate Medium";
  }

  a {
    color: white;
    text-decoration: none;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    span {
      display: inline;
    }

    strong {
      display: inline;
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 12px;
  }
`;

const FooterLinks = styled.div`
  margin-bottom: 14px;
  text-align: center;
  a,
  span {
    color: white;
    text-decoration: none;
    font: 14px / 16px "Slate Light";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;

    span {
      display: none;
    }

    .copyright,
    .privacy {
      position: absolute;
    }
    .copyright {
      left: 100px;
      bottom: 4px;
    }

    .privacy {
      right: 100px;
      bottom: 4px;
    }
  }
`;

const UpperFooter = ({ nav }) => (
  <UpperFooterElem>
    <MaxSidesWrapper>
      <FooterNav nav={nav} />
      <Logo to="/">
        <img src="/assets/logo/logo-white.svg" alt="logo" />
      </Logo>
      <Address>
        <strong>VEREIN STEP</strong>
        <span>, </span>
        Neugasse 12, CH - 6300 Zug,{" "}
        <a href="mailto:info@step-ch-fl.com">info@step-ch-fl.com</a>
        {" | "}
        <a
          target="_blank"
          href="https://www.linkedin.com/company/verein-step/"
          rel="noreferrer"
        >
          LinkedIn
        </a>
      </Address>
      <FooterLinks>
        <a className="copyright">© 2024 Verein STEP</a>
        <span> | </span>
        <Link className="privacy" to="/privacy-notice">
          Privacy Notice
        </Link>
      </FooterLinks>
    </MaxSidesWrapper>
  </UpperFooterElem>
);

export default UpperFooter;
