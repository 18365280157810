import * as React from "react";
import styled from "styled-components";

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const NavigationBackground = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  z-index: 12;
  transition: left 800ms;

  &.open {
    display: inherit;
    left: 0;
  }
`;

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({
  children,
  className,
  navIsOpen,
}) => (
  <>
    <StyledLayoutRoot className={className}>
      <NavigationBackground className={navIsOpen ? "open" : ""} />
      {children}
    </StyledLayoutRoot>
  </>
);

export default LayoutRoot;
