import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../../styles/variables";

const ContentWrapperElem = styled.div`
  padding: 0 ${spacers.sides.mobile}px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  &.marginBottom {
    margin-bottom: 80px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0 ${spacers.sides.desktop}px;

    &.marginBottom {
      margin-bottom: 120px;
    }
  }
`;

const ContentWrapper = ({ children, marginBottom }) => (
  <ContentWrapperElem className={marginBottom ? "marginBottom" : ""}>
    {children}
  </ContentWrapperElem>
);

export default ContentWrapper;
