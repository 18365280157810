import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";

import "normalize.css";
import "../styles/fonts.css";
import "../styles/base.css";

import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import SubNav from "../components/SubNav";
import SubNavPhotos from "../components/SubNavPhotos";
import Sponsors from "../components/Sponsors";

import favicon from "../favicon.ico";

type DefaultLayoutProps = {
  siteSlug?: string;
};

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  siteSlug,
}) => {
  const location = useLocation();
  return (
    <StaticQuery
      query={graphql`
        query DefaultLayoutQuery {
          navigation {
            type
            id
            attributes {
              elementId
              items {
                cssClass
                id
                title
                url
                items {
                  cssClass
                  id
                  title
                  url
                }
              }
            }
          }
        }
      `}
      render={(data: any) => {
        const nav = [];
        const activeSubNav = [];

        let url = "";
        if (typeof location !== "undefined") {
          url = location.pathname;
        }

        data.navigation.attributes?.items?.map((menuItem: any, index) => {
          let counter = 0;
          nav.push(
            <li key={index}>
              <Link
                activeClassName="active"
                to={`${menuItem.url}`}
                onClick={() => {
                  setNavIsOpen(false);
                }}
              >
                {menuItem.title}
              </Link>
            </li>
          );
          const searchedSiteSlug = siteSlug;
          if (url.includes("komittee")) {
            siteSlug = "centres";
          }
          if (menuItem.url.includes(searchedSiteSlug)) {
            counter++;
            activeSubNav.push(
              menuItem.items?.map((menuSubItem: any, index) => (
                <li
                  key={index}
                  className={`
                  ${
                    menuSubItem.url.includes("centres")
                      ? menuSubItem.url.split("/centres/")[1]
                      : ""
                  }`}
                >
                  <Link activeClassName="active" to={`${menuSubItem.url}`}>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: menuSubItem.title,
                        }}
                      />
                    </div>
                  </Link>
                </li>
              ))
            );
          } else {
            menuItem.items?.map((menuSubItemCheck: any) => {
              if (menuSubItemCheck.url.includes(searchedSiteSlug)) {
                counter++;
                activeSubNav.push(
                  menuItem.items?.map((menuSubItem: any) => (
                    <li
                      key={menuSubItem.url}
                      className={`
                    ${
                      menuSubItem.url.includes("centres")
                        ? menuSubItem.url.split("/centres/")[1]
                        : ""
                    } ${menuSubItem.url === url ? "is-active" : ""}`}
                    >
                      <Link activeClassName="active" to={`${menuSubItem.url}`}>
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: menuSubItem.title,
                            }}
                          />
                        </div>
                      </Link>
                    </li>
                  ))
                );
              }
              return null;
            });
          }
          /*
        // pushing mainnav point to first point in subnav
        if (counter > 0) {
          activeSubNav.unshift(
            <li key={index}>
              <Link activeClassName="active" to={`${menuItem.url}`}>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: menuItem.title,
                    }}
                  />
                </div>
              </Link>
            </li>
          );
        }
         */
        });

        const [navIsOpen, setNavIsOpen] = useState(false);

        return (
          <LayoutRoot navIsOpen={navIsOpen}>
            <Helmet>
              <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
            </Helmet>
            <Header
              title="abc"
              navIsOpen={navIsOpen}
              setNavIsOpen={setNavIsOpen}
            />
            <Navigation
              nav={nav}
              navIsOpen={navIsOpen}
              setNavIsOpen={setNavIsOpen}
            />
            {url.includes("komittee/") && activeSubNav.length > 0 ? (
              <>
                <br />
                <br />
                <SubNavPhotos nav={activeSubNav} />
              </>
            ) : url.includes("centres") && activeSubNav.length > 0 ? (
              <>
                <br />
                <br />
                <SubNavPhotos nav={activeSubNav} />
              </>
            ) : activeSubNav.length > 0 ? (
              <>
                <br />
                <br />
                <SubNav nav={activeSubNav} />
              </>
            ) : (
              ""
            )}
            <LayoutMain>{children}</LayoutMain>

            <Sponsors />
            <Footer nav={nav} />
          </LayoutRoot>
        );
      }}
    />
  );
};

export default DefaultLayout;
