/* eslint-disable max-len */

export const colors = {
  blue: "#162053",
  lightBlue: "#4891DB",
  text: "#333333",
  orange: "#E24912",
  lightOrange: "#E99341",
  pink: "#D41B98",
  violet: "#905EA4",
  green: "#2EB135",
  lightGreen: "#BED600",
  grey: "#8D8DA2",
  lightGray: "#E6E6E6",
  fwBgLightBlue: "#586F98",
  fwBgRed: "#7D0107",
  fwBgSand: "#EADECC",
  webinar: "#BED600",
  lunchmeeting: "#E24912",
  hybrid: "#905EA4",
  eveningevent: "#E99341",
  coursesseminar: "#2EB135",
  conference: "#D41B98",
  others: "#8D8DA2",
  jobsvacant: "#8960a0",
  jobswanted: "#c33294",
  miscellaneous: "#8d8da0",
  news: "#182050",
};

// the bg colors could actually just be set in the CMS too.

export const fonts = {
  sansSerif:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  serif: 'Georgia, "Times New Roman", Times, serif',
  monospace:
    'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
};

export const breakpoints = {
  tablet: 768,
  desktop: 1200,
  desktopM: 1500,
  desktopL: 1900,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
};

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18,
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2,
  },
  containerPadding: 1.5,
};

export const heights = {
  header: {
    mobile: 67,
    desktop: 100,
  },
};

export const spacers = {
  sides: {
    mobile: 30,
    desktop: 100,
  },
};
