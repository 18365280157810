import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const SponsorElem = styled.div`
  max-width: 184px;
  margin: 0 auto;
  text-decoration: none;
  h6,
  h6 p {
    margin-bottom: 10px;
    font: 14px / 16px "Slate Medium";
    text-align: center;
    text-transform: uppercase;
  }
  a,
  div {
    img {
      width: 184px;
    }
  }
  @media screen AND (min-width: ${breakpoints.tablet}px) {
    width: 300px;
    max-width: unset;
    height: 200px;
    padding: 0 30px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a,
    div {
      margin-bottom: 0px;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 100%;
        max-width: 240px;
        max-height: 160px;
        margin: 0 auto;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    div {
      width: 100%;

      img {
        max-width: 184px;
      }
    }
  }
`;

const Sponsor = ({ imgurl, imglink, sponsorTitle }) => (
  <SponsorElem target="_blank" href="#">
    {sponsorTitle && <h6 dangerouslySetInnerHTML={{ __html: sponsorTitle }} />}
    {imglink ? (
      <a target="_blank" href={imglink} rel="noreferrer">
        {imgurl && <img src={imgurl} alt="sponsor logo" />}
      </a>
    ) : (
      <div>{imgurl && <img src={imgurl} alt="sponsor logo" />}</div>
    )}
  </SponsorElem>
);

export default Sponsor;
