import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const NavElem = styled.nav`
  margin-bottom: 50px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    list-style-type: none;
    overflow: scroll;
    margin-left: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 14px;

    &::-webkit-scrollbar {
      display: none;
    }

    a {
      text-decoration: none;
    }

    li {
      div {
        width: 120px;
        height: 100%;
        border-bottom-right-radius: 30px;
        background: ${colors.blue};

        p {
          display: block;
          padding: 7px;
          padding-right: 20px;
          color: white;
          text-transform: uppercase;
          font: 13px / 16px "Slate Medium";
        }
      }
    }
    li:last-of-type div {
      margin-right: 14px;
    }

    li:first-of-type div {
      margin-left: 14px;
    }

    li.active,
    li.is-active,
    li:hover,
    li a.active {
      div {
        background: ${colors.lightBlue};
      }
    }
  }

  @media screen AND (min-width: 683px) {
    ul {
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 76px;
    max-width: 1280px;

    ul {
      margin: 0 auto;
      flex-wrap: nowrap;
      overflow: visible;
      gap: 26px;
      li div {
        min-width: 280px;
        border-bottom-right-radius: 70px;

        p {
          font: 24px / 29px "Slate Medium";
        }
      }

      li:last-of-type div {
        margin-right: 26px;
      }

      li:first-of-type div {
        margin-left: 100px;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktopM}) {
    ul {
      li div {
        min-width: unset;
        width: 100%;
        max-width: 20%;
      }
    }
  }
`;

const SubNav = ({ nav }) => (
  <NavElem>
    <ul>{nav}</ul>
  </NavElem>
);

export default SubNav;
