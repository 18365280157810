import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { heights, dimensions, breakpoints, spacers } from "../styles/variables";
import NavToggle from "./Navigation/NavToggle";

const StyledHeaderSpacer = styled.div`
  height: ${heights.header.mobile}px;
  width: 100vw;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: ${heights.header.desktop}px;
  }
`;

const StyledHeader = styled.header`
  height: ${heights.header.mobile}px;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  width: 100vw;
  z-index: 10;

  > div {
    padding: 15px ${spacers.sides.mobile}px;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      margin-top: 4px;
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: ${heights.header.desktop}px;
    > div {
      padding: 17px ${spacers.sides.desktop}px;

      > div {
        margin-top: 15px;
      }
    }
  }

  /*
    @media screen AND (min-width: ${breakpoints.desktop}px) {
    height: ${heights.header.desktop}px;
    > div {
      padding: 17px ${spacers.sides.mobile}px;

      > div {
        margin-top: 15px;
      }
    }

  }
  @media screen AND (min-width: ${breakpoints.desktopL}px) {
    > div {
      padding: 17px ${spacers.sides.desktop}px;
    }
  */
`;

const HomepageLink = styled(Link)`
  img {
    width: 117.5px;
    height: 42px;
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    img {
      width: 187.5px;
      height: 67px;
    }
  }
`;

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ navIsOpen, setNavIsOpen }) => (
  <>
    <StyledHeaderSpacer />
    <StyledHeader>
      <div>
        <HomepageLink to="/">
          <img src="/assets/logo/logo.svg" />
        </HomepageLink>
        <NavToggle setNavIsOpen={setNavIsOpen} navIsOpen={navIsOpen} />
      </div>
    </StyledHeader>
  </>
);

export default Header;
