import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";

const NavElem = styled.nav`
  margin-bottom: 50px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;
    list-style-type: none;
    overflow: scroll;
    margin-left: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      &.zurich {
        div {
          background: url("/assets/gestaltungselemente/subnav/zurich.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      &.vaduz {
        div {
          background: url("/assets/gestaltungselemente/subnav/vaduz.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      &.lugano {
        div {
          background: url("/assets/gestaltungselemente/subnav/lugano.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      &.lucerne-zug {
        div {
          background: url("/assets/gestaltungselemente/subnav/luzern-zug.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      &.basel {
        div {
          background: url("/assets/gestaltungselemente/subnav/basel.png");
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }

      div {
        width: 123px;
        height: 115px;
        filter: grayscale(100%);
        opacity: 0.5;
        margin-right: 14px;

        p {
          visibility: hidden;
        }
      }
    }
    li:last-of-type div {
      margin-right: 14px;
    }

    li:first-of-type div {
      margin-left: 14px;
    }

    a.active,
    a:hover {
      div {
        opacity: 1;
        filter: grayscale(0%);
        p {
          opacity: 1;
        }
      }
    }
  }

  @media screen AND (min-width: 750px) {
    ul {
      justify-content: center;
    }
  }

  @media screen AND (min-width: 1280px) {
    margin-bottom: 70px;

    ul {
      justify-content: center;
      li div {
        width: 225px;
        height: 210px;
        margin-right: 26px;
      }
    }
  }
`;

const SubNavPhotos = ({ nav }) => (
  <NavElem>
    <ul>{nav}</ul>
  </NavElem>
);

export default SubNavPhotos;
