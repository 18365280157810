import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import NavToggle from "./Navigation/NavToggle";

const NavigationElem = styled.nav`
  padding: 16px 16px 30px 30px;
  height: 100vh;
  width: 100vw;
  background: ${colors.blue};
  position: fixed;
  top: 0;
  right: -100vw;
  transition: right 300ms;
  z-index: 15;
  overflow-y: scroll;

  ul {
    list-style-type: none;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  a {
    color: white;
    text-decoration: none;
    font: 28px / 50px "Slate Book";
    text-transform: uppercase;
    &:hover,
    &.active {
      color: ${colors.lightBlue};
    }
  }

  .togglePositioningDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &.open {
    right: 0;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    max-width: 450px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    .togglePositioningDiv {
      margin-top: 15px;
      max-width: 404px;
      > div {
        margin-right: 85px;
      }
    }
  }
  @media screen AND (min-width: ${breakpoints.desktopL}px) {
    max-width: unset;
    width: calc(((100vw - 1900px) / 2) + 450px);
  }
`;

const Navigation = ({ nav, navIsOpen, setNavIsOpen }) => (
  <NavigationElem className={navIsOpen ? "open" : ""}>
    <div className="togglePositioningDiv">
      <NavToggle navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
    </div>
    <ul>{nav}</ul>
  </NavigationElem>
);

export default Navigation;
