import React from "react";
import styled from "styled-components";

const NavToggleElem = styled.div`
  height: 32px;
  width: 49px;
  position: relative;
  cursor: pointer;

  > div {
    width: 100%;
    height: 3px;
    background: black;
    position: absolute;
    transition: all 300ms;

    &:first-of-type {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 14px;
    }

    &:nth-of-type(3) {
      top: 28px;
    }
  }

  &.active {
    > div {
      background: white;

      &:first-of-type {
        top: 14px;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        background: rgba(0, 0, 0, 0);
      }

      &:nth-of-type(3) {
        top: 14px;
        transform: rotate(-45deg);
      }
    }
  }
`;

const NavToggle = ({ navIsOpen, setNavIsOpen }) => (
  <NavToggleElem
    className={navIsOpen ? "active" : ""}
    onClick={() => setNavIsOpen(!navIsOpen)}
  >
    <div />
    <div />
    <div />
  </NavToggleElem>
);

export default NavToggle;
