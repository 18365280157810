import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const FooterNavElem = styled.nav`
  display: none;
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font: 18px / 22px "Slate Book";
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: block;
    padding-top: 12px;
  }
`;

const FooterNav = ({ nav }) => (
  <FooterNavElem>
    <ul>{nav}</ul>
  </FooterNavElem>
);

export default FooterNav;
