import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";
import UpperFooter from "./Footer/UpperFooter";
import MaxSidesWrapper from "./Wrappers/MaxSidesWrapper";

const FooterElem = styled.footer``;

const LowerFooter = styled.div`
  img {
    display: block;
    width: 100%;
    max-width: 320px;
    margin: 12px auto;
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    img {
      max-width: 400px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    img {
      max-width: 666px;
    }
  }
`;

const Footer = ({ nav }) => (
  <FooterElem>
    <UpperFooter nav={nav} />
    <LowerFooter>
      <MaxSidesWrapper>
        <img src="/assets/gestaltungselemente/values.png" alt="Values" />
      </MaxSidesWrapper>
    </LowerFooter>
  </FooterElem>
);

export default Footer;
